.modal__button {
  padding: 8px 12px;
  border: 1px solid var(--primary);
  background-color: transparent;
  border-radius: 4px;
  color: var(--primary);
  font-weight: 500;
  transition: 0.3s all ease;
}

.modal__button.secondary {
  border: none;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.modal__button:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.modal_title {
  display: flex;
}

.modal-title-icon {
  font-size: 30px;
  padding-right: 5px;
  margin-right: 10px;
}

.btn-close:hover {
  cursor: pointer;
}

.modal-title {
  font-size: 20px;
}

.modal-header {
  border-bottom: 1px solid var(--primary-50);
}

.form__container {
  padding: 2rem 3rem 6rem 3rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
  background-color: #fff;
  max-width: 1000px;
  margin: -7rem auto 0 auto;
  border: 2px solid #cecece;
}

.form__container h2 {
  margin-bottom: 1rem;
  font-size: 20px;
  margin-top: 2rem;
}

.form__container h2 + p {
  color: var(--text-grey);
  font-weight: 500;
  margin-top: -1rem;
  font-size: 14px;
}

.form__container form {
  margin-top: 2rem;
}

.form__container span {
  color: red;
}

hr {
  color: #f6f1f129;
  margin-top: 0;
  margin-bottom: 2rem;
}

label {
  font-size: 14px;
  color: #505050;
}

input,
select {
  padding: 7px;
  font-size: 14px;
  background: #fff;
  border: 2px solid transparent;
  width: 65%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
  border-radius: 4px;
  color: #3a3a3a;
}

input,
select,
textarea {
  margin-bottom: 2rem;
  margin-top: 4px;
  border: 1px solid #d3d3d3de;
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--secondary);
  outline: none;
}

textarea {
  resize: none;
  border: 1px solid #d3d3d3de;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
  font-family: inherit;
  width: 100%;
  padding: 7px;
  font-size: 14px;
  color: #3a3a3a;
}

.submit__button {
  outline: none;
  padding: 12px 30px;
  border: none;
  letter-spacing: 1px;
  background-color: var(--primary);
  color: white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.5s ease;
  float: right;
}

.submit__button:hover,
.submit__button:focus {
  transform: translateY(-3px);
}

.submit__button:disabled {
  pointer-events: none;
  background-color: #d0d0d0;
}

.bug__container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.bug__container_small {
  width: calc(50% - 32px);
}

.bug__container_small select,
.bug__container_small input {
  width: 100%;
}

@media (max-width: 768px) {
  .bug__container {
    display: block;
  }

  .bug__container_small {
    width: 100%;
  }

  input,
  select {
    width: 100%;
  }
}
