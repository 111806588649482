@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&family=Raleway:wght@300;400;500;700&display=swap");
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/_variables";
@import "../node_modules/bootstrap/scss/_mixins";
@import "~bootstrap/scss/_modal.scss";
@import "~bootstrap/scss/_close.scss";
// @import "~bootstrap/scss/bootstrap";

/* Global styling */
:root {
  --secondary: #ff6a00;
  --umc-dark-blue: #004289;
  --umc-seaweed-green: #437c17;
  --umc-warm-yellow: #f1c641;
  --bg-light: #f8f9fa;
  --bg-darker: #f5f6fa69;
  --text-grey: rgba(10, 20, 47, 0.56);
  --dark-blue: #001c3d;
  --light-blue: #009fd8;
  --dark-orange: #e54e10;
  --light-orange: #f09125;
  --red: #ae0b12;
  --green: #0f9a58;
  --yellow: #ffffc9;
  --dark-grey: #f5f6fa;
  --light-grey: #c9c9c9;
  --text-dark: #091e42;
  --gray10: #e3e3e3;
  --gray05: #eeeef0;
  --gray00: #fff;
  /* Primary Blue color scheme where the lower the number the lighter blue*/
  --primary-50: #e9f4ff;
  --primary-100: #d2e8fe;
  --primary-200: #a5d1fc;
  --primary-300: #77bafb;
  --primary-400: #4aa3f9;
  --primary: #009fd8;
  --primary-600: #1669ba;
  --primary-700: #0f467c;
  --primary-800: #07233e;
  --primary-900: #030e19;
  /* Padding System */
  --space-unit: 16px;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  overflow: auto;
}

h1,
h2,
h3 {
  font-family: "Poppins", "Raleway", sans-serif;
  color: var(--text-dark);
}

a {
  text-decoration: none;
}

section {
  margin: 5rem auto;
}

.title__container {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title__container h3 {
  font-size: 28px;
  font-weight: 500;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 769px) and (max-width: 1399px) {
  .data__wrapper, .tabs__container  {
    padding: 0 3rem !important;
  }
}

@media (min-width: 1400px) {
  .data__wrapper, .tabs__container {
    width: 70%;
    padding: 0 !important;
  }

  .data__wrapper {
    margin: 5rem auto;
  }

  .data__wrapper.inner_nav__container {
    margin: 2rem auto;
  }

  .data__wrapper.single {
    margin-top: 0;
  }

  .title__container h3 {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
}
