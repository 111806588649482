.tabs__container {
  display: flex;
  justify-content: space-around;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: -50px auto 0 auto;
  align-items: center;
  font-size: 16px;
}

.tabs__tab {
  background-color: white;
  text-align: center;
  height: 80px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s box-shadow ease;
}

.tabs__container a {
  font-weight: 500;
  width: calc(25% - 8px);
  color: var(--text-dark);
  text-decoration: none;
}

.tabs__tab svg {
  color: #838383;
}

.tabs__tab:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

a.active > .tabs__tab {
  border: 2px solid var(--primary);
}

a.active > .tabs__tab > svg {
  color: var(--primary);
}

@media (max-width: 668px) {
  .tabs__container {
    margin-top: -64px;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 14px;
  }

  .tabs__container a {
    width: calc(50% - 8px);
    margin-bottom: var(--space-xxs);
    margin-right: var(--space-xs);
  }

  .tabs__tab {
    height: 60px;
  }
}
