.header__container {
    height: 180px;
    background-color: var(--bg-darker);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e8e8e861;
}

.header__titles {
    text-align: center;
}

.header__titles h1 {
    font-weight: 300;
    font-size: 16px;
    margin: 0;
}

.header__titles h2 {
    margin: 0;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
}