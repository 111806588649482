/* General */
.data__container {
  margin: auto;
  margin-bottom: 5rem;
}

.data__wrapper.single {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
}

.data__wrapper .data__wrapper_single {
  width: 80%;
  padding-right: 1rem;
  margin-bottom: 5rem;
}

h3.related-articles-header {
  margin-top: 50px;
}

.header__container {
  margin-bottom: 20px;
}

h2 {
  scroll-margin-top: 100px;
  scroll-snap-margin-top: 100px;
  /* iOS 11 and older */
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.008em;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

p {
  font-size: 0.95em;
  line-height: 1.914;
  font-weight: normal;
  margin-bottom: 1rem;
  letter-spacing: -0.005em;
  color: var(--text-dark);
}

ul,
ol {
  margin-left: 2rem !important;
  margin-bottom: 2rem;
}

li p {
  margin-bottom: 0.5rem;
}

.data__container a {
  text-decoration: none;
  font-weight: 300;
  color: var(--primary-400);
}

.inner_nav__container {
  margin: 2rem auto;
}

.inner_nav__container span {
  transition: all 0.2s ease;
  color: var(--text-dark);
  font-size: 14px;
  cursor: pointer;
}

.inner_nav__container span:hover {
  color: var(--primary-400);
}

.innerCell a {
  color: var(--primary);
  font-weight: 600;
}

/* Panel macros general */
.confluence-information-macro-body p,
.panelContent p {
  margin: 0;
}

.confluence-information-macro-body h2 {
  margin-top: 0;
}

.confluence-information-macro {
  display: flex;
  align-items: center;
  min-height: 48px;
  margin: 2rem 0;
}

.confluence-information-macro::before {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  padding: 12px;
  vertical-align: middle;
  font-weight: 900;
  font-size: 24px;
}

/* Tip macro */
.confluence-information-macro-tip {
  background-color: #e3fcef;
  padding: 16px 12px;
}

.confluence-information-macro-tip::before {
  content: "\f058";
  color: var(--green);
}

/* note macro */
.confluence-information-macro-note {
  background-color: var(--yellow);
  padding: 16px 12px;
}

.confluence-information-macro-note::before {
  content: "\f071";
  color: var(--light-orange);
}

.confluenceTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

.confluenceTable p {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  font-size: 1em !important;
}

.confluenceTable tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.confluenceTh {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.confluenceTd {
  font-size: 14px !important;
  vertical-align: top;
}

.confluenceTd p em strong u,
.confluenceTd p u strong em {
  display: none;
}

.confluenceTd h4 {
  font-weight: normal;
}

a.read-more-link {
  padding-left: 4px;
}

ol[start="1"] {
  list-style: none;
  counter-reset: list;
}

ol ol {
  margin-bottom: 0px;
}

ol > li {
  counter-increment: list;
  margin-bottom: 5px;
}

ol > li:before {
  content: counters(list, ".");
  margin-right: 10px;
  margin-bottom: 10px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: var(--primary);
  border-radius: 50%;
  color: #fff;
  vertical-align: top;
  margin-top: 2px;
}

ol > li > p {
  max-width: 70%;
  display: inline-block;
}

#how-to ol > li > p strong {
  padding-left: 5px;
  padding-right: 5px;
}

/* Support for very small screens  */
@media only screen and (max-width: 1000px) {
  .columnLayout.two-equal .cell {
    width: 100%;
  }

  .columnLayout.two-equal {
    display: block;
  }

  img {
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .data__wrapper.single {
    display: block;
    margin-top: 2rem;
  }

  .data__wrapper .data__wrapper_single {
    margin-top: 2rem;
    width: 100%;
  }
}
