.related-document {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-bottom: 12px;
  margin-top: 12px;
  padding: var(--space-md) var(--space-md);
  flex: 0 0 calc(33% - 12px);
  color: var(--text-dark);
  text-decoration: none;
  transition: all 0.5s ease;
  font-size: 0.95em;
}

.related-document:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.related-document-title {
  font-weight: bolder;
  margin-bottom: var(--space-unit);
}

.related-document-link {
  float: right;
  position: relative;
  color: var(--primary);
}

.related-document-link a  {
  font-size: 12px;
  display: inline-block;
  text-indent: -999em;
  margin-top: -16px;
}
.related-document-link a:after  {
  font-family: "FontAwesome";
  display: block;
  content: "\f08e";
  text-indent: 0;
}

@media (max-width: 768px) {
  .related-document {
    display: inherit;
    flex: 0 0 calc(50% - 12px);
  }
}

@media (max-width: 468px) {
  .related-document {
    display: inherit;
    flex: 0 0 100%;
  }
}