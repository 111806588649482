.error__message {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

.error__container {
  display: flex;
}

.error__icon {
  margin-right: 10px;
}
