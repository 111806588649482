#related-documents-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

#quick_reference {
    margin-top: 0 !important;
}

.related-documents__container h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem;
}

.related-documents__side_container {
    width: 40%;
    align-self: flex-start;
}

.related-documents__side_list a {
    display: inherit;
    font-size: 14px;
    max-width: 350px;
}

@media (max-width: 768px) {
    .related-documents__side_container {
        width: 100%;
        margin-bottom: 3rem;
    }

    .related-documents__side_list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }
}