.navbar {
  height: 70px;
  background-color: var(--dark-blue);
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
  position: sticky;
  top: 0;
  z-index: 100;
}
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--dark-orange);
  transform-origin: 0%;
}
.navbar a {
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  font-size: 14px;
}

.navbar a:hover {
  color: var(--light-orange);
}

.navbar a svg {
  padding-right: var(--space-xxs);
  font-size: 25px;
}

.navbar__breadcrumbs,
.navbar__login {
  display: flex;
  gap: 12px;

}

.navbar__breadcrumbs {
  width: 56px;
}

/* MOBILE NAVBAR */
.navbar__hamburger {
  font-size: 25px;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  color: #fff;
  cursor: pointer;
}

.navbar__dropdown_mobile {
  position: absolute;
  top: 50px;
  right: 0;
  border-bottom-left-radius: 10px;
  background-color: var(--dark-blue);
  border-bottom-right-radius: 10px;
  width: 100vw;
  height: 200px;
  display: flex;
  align-items: center;
  transition: 0.5s all ease;
}
.navbar__internal_links {
  width: 100%;
}
.navbar__internal_links a {
  font-size: 18px;
}
.navbar__internal_links a:first-of-type {
  margin-bottom: 2rem;
}

/* DESKTOP NAVIGATION */
/* Not displayed on small screens */
.navbar__desktop {
  display: none;
}

@media (max-width: 776px) {
  .navbar {
    padding: 0 var(--space-unit);
  }
}
@media only screen and (max-width: 1000px) {
  .navbar__breadcrumbs img {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .navbar__hamburger.flex, .navbar__dropdown_mobile {
    display: none;
  }
  .navbar__desktop {
    display: flex;
  }
}
@media (min-width: 1400px) {
  .navbar {
    padding: 0 10vw;
  }
}
