
.data__wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
}

.data__wrapper.data__wrapper_home {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.getting_started_data__container h2 {
    margin-bottom: 2rem;
    border-bottom: 1px solid #cdcdcd94;
    padding-bottom: 1rem;
}

#quick_reference {
    border: none;
    margin-bottom: 1rem;
  }
  

.data__wrapper_home .getting_started_data__container {
    width: 70%;
}

.toc__container {
    text-align: right;
    font-size: 14px;
    position: sticky;
    top: 5.5rem;
    align-self: flex-start;
    max-width: 300px;
}

.toc__container h4 {
    margin-bottom: 1rem;
}

.toc__container ul {
    list-style: none !important;
    margin-bottom: 0;
}

.toc__container ul li {
    margin-bottom: 8px;
}

.toc__container ul li a {
    color: var(--text-dark) !important;
    display: inline-block;
    width: 100%;
}

.toc__container ul li a:hover {
    background-color: #e3e3e3;
}

.toc-macro:nth-of-type(2) {
    display: none;
}

span {
    transition: transform .1s;
}

/* Image hover effect */

.columnLayout.two-left-sidebar .cell.normal .innerCell {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    height: 100%;
}

.columnLayout.two-left-sidebar .cell.normal .innerCell .confluence-embedded-manual-size {
    width: 25px;
    margin: 0 auto;
}

.innerCell span:first-of-type img {
    width: 20px;
    margin-top: 1rem;
}

.innerCell span:first-of-type:hover~span {
    transform: translateY(-155px) scale(1.7);
}

/* Two column layout with images styling */
.image-center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.columnLayout.two-left-sidebar {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
}

.columnLayout.two-left-sidebar .cell.aside {
    width: 35%;
    display: flex;
    align-items: center;
}

.columnLayout.two-left-sidebar .cell.normal {
    align-items: self-start !important;
    margin-top: 0.5rem;
    width: 65%;
}

.columnLayout.two-left-sidebar .cell.normal:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.columnLayout.two-left-sidebar img {
    max-width: 90%;
}

img {
    max-width: 90%;
}


@media (max-width: 768px) {
    .data__wrapper.data__wrapper_home {
        display: block;
        margin-top: 2rem;
    }

    .data__wrapper_home .getting_started_data__container {
        margin-top: 2rem;
        width: 100%;
    }

    .toc__container {
        text-align: left;
        position: unset;
    }

    .toc__container ul {
        margin-left: 0 !important;
    }
    
}

@media (max-width: 1400px) {
    .columnLayout.two-left-sidebar {
        display: block;
    }

    .columnLayout.two-left-sidebar .cell.aside {
        width: 100%;
    }

    .columnLayout.two-left-sidebar .cell.normal {
        width: 100%;
    }
}