#contact {
  padding: 5vh 10vw;
  background-color: var(--dark-blue);
}
.footer__content {
  padding: 3vh 0;
  color: var(--gray00);
}
#contact h4 {
  margin-top: 0;
  margin-bottom: 6vh;
  text-align: center;
}

h4 a {
  color: var(--light-blue);
}
#contact a {
  text-decoration: none;
  margin: 0;
}
.footer__border {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    var(--gray10),
    rgba(0, 0, 0, 0)
  );
  border-top: none !important;
  height: 1px;
  background-color: transparent;
  border: 0;
  opacity: 0.55;
  margin-bottom: 1rem;
}
.footer__logo_container {
    margin: 0 auto 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.footer__logo_container img{
  object-fit: contain;
  width: 200px;
  height: 30px;
}
.footer__header {
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
}
.footer__content h5 {
  color: var(--light-orange);
  margin: 0;
}
.footer__content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.75;
}
.footer__body {
  flex-wrap: wrap;
  justify-content: center;
}
.footer__body_item {
  flex: 1 0 calc(50% - 10px);
  margin-bottom: 3vh;
  padding-right: 5px;
}

a > p {
  transition: all 0.3s ease;
}
a > p:hover,
a > p:visited,
a > p:focus {
  color: var(--light-blue);
  font-weight: 700;
}
.footer__body_item p {
  margin: 0;
  color: var(--gray10);
}
.footer__footer {
  text-align: center;
  font-size: 12px;
  letter-spacing: 1px;
  color: white;
}
.footer__footer svg {
  font-size: 25px;
  padding-left: 5px;
  padding-top: 5px;
}
.linkedin {
  color: var(--gray00);
}
.github {
  color: var(--gray00);
}
.flex {
  display: flex;
}
@media screen and (min-width: 800px) {
  .footer__body_item {
    flex: 1 0 calc(33% - 10px);
  }
  .footer__logo_container {
    flex-direction: row;
    width: 80%;
  }
  #UM, #MUMC {
    height: 50px;
    width: 220px;
  }
}
@media screen and (min-width: 900px) {
  #contact {
    padding: 5vh 15vw;
  }
}
@media screen and (min-width: 1200px) {
  .footer__body_item {
    flex: 1 0 calc(25% - 10px);
  }
  .footer__logo_container {
    width: 600px;
  }
}
@media screen and (min-width: 1400px) {
  #contact {
    padding: 5vh 18vw;
  }
}
